import { Component, OnInit } from "@angular/core";
import { ILoginComponent } from "./login.interface";
import { AppURL } from "src/app/app.url";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClientService } from "src/app/shareds/http-client/http-client.service";
declare const App: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements ILoginComponent {

  error: any;

  public showOverlay = true;
  submitted = false;
  AppURL = AppURL;
  form: FormGroup;

  constructor(
    private FormBuilder: FormBuilder,
    private alert: ToastrService,
    private httpClient: HttpClientService,
    private router: Router
  ) {
    this.initialCreateFormData();
    if (this.httpClient.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  

 

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  private initialCreateFormData() {
    this.form = this.FormBuilder.group({
      PhoneNumber: ['', Validators.required],
      Password: ['', Validators.required]
    });
    this.showOverlay = false
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.httpClient.login(this.form.value.PhoneNumber, this.form.value.Password)
      .pipe(first())
      .subscribe(response => {
        if (response.status === true) {
          this.alert.success('แจ้งเตือน!', response.message,  {
            positionClass: 'toast-bottom-left',
          });
          location.href = '/';
        } else {
          this.alert.error('เกิดข้อผิดพลาด!', response.message,  {
            positionClass: 'toast-bottom-left',
          });
        }
      }, error => {
        this.error = error;
      });
    return;
  }



}
