import { Component, HostListener, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClientService } from "src/app/shareds/http-client/http-client.service";
import { environment } from "src/environments/environment";
import { SocketioService } from "src/app/shareds/socket/socketio.service";
import { Lightbox, LightboxConfig } from "ngx-lightbox";

declare var $: any;
import * as _ from "lodash";

@Component({
  selector: "app-tree-examine",
  templateUrl: "./tree-examine.component.html",
  styleUrls: ["./tree-examine.component.css"],
})
export class TreeExamineComponent implements OnInit {
  @HostListener("scroll", ["$event"])
  showOverlay = true;
  error: any;
  submitted = false;
  submittedVariety = false;
  url = `${environment.apiUrl}`;

  TreeExamineForm: FormGroup;
  itemsPerPage = 15;
  PageNumber = 1;
  config = {
    totalItems: 0,
  };
  limit = 0;

  filter = "";
  tree_type_id = "";
  province = "";
  district = "";
  subdistrict = "";
  user_level_id = "";
  phone_number = "";

  loading = false;
  LogoDefault = "assets/media/icons/default-placeholder.png";
  itemsPerSelect = 10;
  CountTree: any;
  Pictures = [];
  RoleID = localStorage.getItem("RoleID");
  notFoundTemplate: any;

  isDisabled: Boolean = true;

  constructor(
    private FormBuilder: FormBuilder,
    private router: Router,
    private alert: ToastrService,
    private httpClient: HttpClientService,
    private socketService: SocketioService,
    private _lightbox: Lightbox,
    private _lightboxConfig: LightboxConfig
  ) {
    this._lightboxConfig.centerVertically = true;
    this._lightboxConfig.disableScrolling = true;
    this._lightboxConfig.wrapAround = true;
  }

  ngOnInit() {
    this.TreeExamineForm = this.FormBuilder.group({
      TreeID: [""],
      TreeTypeID: ["", Validators.required],
      TreeName: ["", Validators.required],
      Name: [""],
      Description: [""],
      EngName: [""],
      ScientificName: [""],
      TreeFamilyName: [""],
      TreeFamilyNameID: [""],
      VarietyID: [""],
      VarietyName: [""],
      VarietyEngName: [""],
      VarietyScientificName: [""],
      VarietyFamilyName: [""],
      VarietyFamilyNameID: [""],
      VarietyDescription: [""],
    });
    this.showOverlay = false;
    // this.getTrees(this.filter)
    this.setStatus();
    this.GetTreeCountBySocket();
    this.getTreeType();
    this.getUserLevels();
    this.getProvinces();
    this.getTreeFamilyName();
  }

  get m() {
    return this.TreeExamineForm.controls;
  }

  TreeFamilyName: any;
  getTreeFamilyName() {
    this.httpClient
      .getData("/tree_family_name/list?data_type_id=1")
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.TreeFamilyName = response.data.data;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  errorHandler(event) {
    event.target.src = this.LogoDefault;
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.Pictures, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  setStatus() {
    this.showOverlay = true;
    const ID = localStorage.getItem("last_tree_register");
    if (localStorage.getItem("type_tree_register") == "Home") {
      this.updateRegisterHomeStatus("", ID, true); // กลับไปเป็นรอดำเนินการ
    } else if (localStorage.getItem("type_tree_register") == "Public") {
      this.updateRegisterPublicStatus("", ID, true); // กลับไปเป็นรอดำเนินการ
    } else {
      if (localStorage.getItem("last_place_register")) {
        this.updateRegisterPublicStatus(
          "",
          localStorage.getItem("last_place_register"),
          true
        ); // กลับไปเป็นรอดำเนินการ
      } else {
        this.getTreeRegisters(
          this.filter,
          this.tree_type_id,
          this.province,
          this.district,
          this.subdistrict,
          this.user_level_id,
          this.phone_number,
          0,
          this.itemsPerPage,
          true
        );
      }
    }
  }

  historyList = false;
  history() {
    this.showOverlay = true;
    // let type = this.historyList ? 'history' : 'process'
    if (this.historyList) {
      this.historyList = false;
    } else {
      this.historyList = true;
    }
    this.filter = "";
    this.tree_type_id = "";
    this.province = "";
    this.district = "";
    this.subdistrict = "";
    this.user_level_id = "";
    this.phone_number = "";
    this.Districts = [];
    this.Subdistricts = [];
    this.indexActive = undefined;
    let TreeRegisterData = this.TreeRegister
    this.TreeRegister = null;
    this.showOverlay = true
    this.TreeRegisters = [];
    if (TreeRegisterData && TreeRegisterData.Type == "Home") {
      this.updateRegisterHomeStatus("", TreeRegisterData ? TreeRegisterData.ID : '', true); // กลับไปเป็นรอดำเนินการ
    } else if (TreeRegisterData && TreeRegisterData.Type == "Public") {
      this.updateRegisterPublicStatus("", TreeRegisterData ? TreeRegisterData.ID : '', true); // กลับไปเป็นรอดำเนินการ
    } else {
      this.getTreeRegisters(
        this.filter,
        this.tree_type_id,
        this.province,
        this.district,
        this.subdistrict,
        this.user_level_id,
        this.phone_number,
        0,
        this.itemsPerPage,
        true
      );
    }
    // this.TreeRegisters = [];
    // this.resetSearch();
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop + 1 >=
      event.target.scrollHeight &&
      this.TreeRegisters.length < this.config.totalItems
    ) {
      this.getTreeRegisters(
        this.filter,
        this.tree_type_id,
        this.province,
        this.district,
        this.subdistrict,
        this.user_level_id,
        this.phone_number,
        this.itemsPerPage,
        this.itemsPerPage,
        false
      );
    }
  }

  filterSearch() {
    this.indexActive = undefined;
    this.limit = 0;
    this.setStatus();
  }

  TreeRegisters: any = [];
  TotalItem = 0;
  getTreeRegisters(
    filter: string,
    tree_type_id: any,
    province: string,
    district: string,
    subdistrict: string,
    user_level_id: any,
    phone_number: string,
    limit: number,
    itemsPerPage: number,
    New: boolean
  ) {
    if (New) {
      this.limit = 0;
      this.TreeRegisters = [];
    }
    let history_tree = 0;
    if (this.historyList) {
      history_tree = 1;
    }
    this.limit += limit;
    this.httpClient
      .getData(
        `/tree_register_public/tree/examine?filter=${filter}&tree_type_id=${tree_type_id}&province=${province}&district=${district}&subdistrict=${subdistrict}&user_level_id=${user_level_id}&phone_number=${phone_number}&limit=${this.limit}&&item_per_page=${itemsPerPage}&history_tree=${history_tree}`
      )
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            for (const value of response.data.data) {
              this.TreeRegisters.push(value);
            }
          }
          this.config.totalItems = response.data
            ? response.data.total_record
            : 0;
          if (!this.historyList) {
            this.httpClient.currentExamineTree = response.data
              ? response.data.total_record
              : 0;
          }


          if (
            !filter &&
            !tree_type_id &&
            !province &&
            !district &&
            !subdistrict &&
            !user_level_id &&
            !phone_number
          ) {
            if (!this.historyList)
              this.sendTreeCountBySocket(this.config.totalItems);
          }

          this.showOverlay = false;
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  GetTreeCountBySocket() {
    this.socketService.getCountTree().subscribe((countTree: any) => {
      this.CountTree = countTree;
    });
  }

  sendTreeCountBySocket(countTree: any) {
    this.socketService.sendCountTree(countTree);
  }

  GetByTreeID(TreeID: any) {
    this.httpClient
      .getData(`/tree/${TreeID}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            const Tree = response.data;
            this.TreeExamineForm.patchValue({
              TreeTypeID: Tree.TreeTypeID ? Tree.TreeTypeID : "",
              TreeName: Tree.Name,
              Name: Tree.Name,
              TreeID: Tree.ID,
              Description: Tree.Description,
              EngName: Tree.EngName,
              ScientificName: Tree.ScientificName,
              TreeFamilyNameID: Tree.TreeFamilyNameID
                ? Tree.TreeFamilyNameID
                : "",
            });
            this.getTrees(this.filter);
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", response.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  GetByTreeVarietyID(TreeVarietyID: any) {
    this.httpClient
      .getData(`/tree_variety/${TreeVarietyID}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            const TreeVariety = response.data;
            this.TreeExamineForm.patchValue({
              VarietyName: TreeVariety.Name,
              VarietyID: TreeVariety.ID,
              VarietyEngName: TreeVariety.EngName,
              VarietyScientificName: TreeVariety.ScientificName,
              VarietyFamilyNameID: TreeVariety.TreeFamilyNameID
                ? TreeVariety.TreeFamilyNameID
                : "",
              VarietyDescription: TreeVariety.Description,
            });
            this.getTrees(this.filter);
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  Trees: any = [];
  getTrees(filter: string) {
    if (filter == `'` || filter == `''` || filter == `"` || filter == `""`) {
      this.TreeExamineForm.patchValue({
        TreeName: ``,
      });
      filter = ``;
    }
    this.httpClient
      .getData(`/trees?filter=${filter}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.Trees = response.data ? response.data.data : [];
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  Varieties: any = [];
  getSearchVarieties(filter: any) {
    this.httpClient
      .getData(`/tree_variety/tree/${this.TreeID}?filter=${filter}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.Varieties = response.data ? response.data.data : [];
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  resetSearch() {
    this.filter = "";
    this.tree_type_id = "";
    this.province = "";
    this.district = "";
    this.subdistrict = "";
    this.user_level_id = "";
    this.phone_number = "";
    this.Districts = [];
    this.Subdistricts = [];
    this.indexActive = undefined;
    this.TreeRegister = null;
    this.showOverlay = true
    // this.setStatus();
    this.resetStatus();
    // this.filterSearch()
  }

  TreeNameForNotification = ``;
  checkStatus(value: any, index: number) {
    this.httpClient
      .getData(`/tree_register_public/check/status/${value.ID}`)
      .pipe(first())
      .subscribe(
        async (response: any) => {
          if (response.status == false) {
            this.TreeNameForNotification = value.Name;
            $("#modal-noti-status").modal("show");
            this.indexActive = undefined;
            // this.TreeRegisters[index].StatusName = "รอดำเนินการ";
            this.TreeRegister = null;
            this.TreeExamineForm.patchValue({
              // ต้นไม้
              TreeTypeID: "",
              TreeName: "",
              Name: "",
              TreeID: "",
              Description: "",
              EngName: "",
              ScientificName: "",
              TreeFamilyName: "",
              TreeFamilyNameID: "",
              // สายพันธุ์์
              VarietyName: "",
              VarietyID: "",
              VarietyEngName: "",
              VarietyScientificName: "",
              VarietyFamilyName: "",
              VarietyFamilyNameID: "",
              VarietyDescription: "",
            });
            if (this.TreeRegisterOld) {
              if (this.TreeRegisterOld.Type == "Home") {
                this.updateRegisterHomeStatus(
                  "",
                  this.TreeRegisterOld.ID,
                  false
                ); // กลับไปเป็นรอดำเนินการ
              } else {
                this.updateRegisterPublicStatus(
                  "",
                  this.TreeRegisterOld.ID,
                  false
                ); // กลับไปเป็นรอดำเนินการ
              }
            } else {
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                this.limit,
                this.TreeRegisters.length,
                true
              );
            }
          } else {
            localStorage.setItem("last_tree_register", value.ID);
            // localStorage.setItem("type_tree_register", value.Type);
            this.indexActive = index;
            this.TreeID = value.TreeID;
            this.TreeExamineForm.patchValue({
              // ต้นไม้
              TreeTypeID: value.TreeTypeID ? value.TreeTypeID : "",
              TreeName: value.TreeName ? value.TreeName : "",
              Name: value.Name ? value.Name : "",
              TreeID: value.TreeID,
              Description: value.Description,
              EngName: value.EngName,
              ScientificName: value.ScientificName,
              TreeFamilyName: value.TreeFamilyName ? value.TreeFamilyName : "",
              TreeFamilyNameID: value.TreeFamilyNameID
                ? value.TreeFamilyNameID
                : "",
              // สายพันธุ์์
              VarietyName: value.VarietyName ? value.VarietyName : "",
              VarietyID: value.TreeVarietyID ? value.TreeVarietyID : "",
              VarietyEngName: value.VarietyEngName ? value.VarietyEngName : "",
              VarietyScientificName: value.VarietyScientificName
                ? value.VarietyScientificName
                : "",
              VarietyFamilyName: value.VarietyFamilyName
                ? value.VarietyFamilyName
                : "",
              VarietyFamilyNameID: value.VarietyFamilyNameID
                ? value.VarietyFamilyNameID
                : "",
              VarietyDescription: value.VarietyDescription
                ? value.VarietyDescription
                : "",
            });
            this.Varieties = [];
            this.TreeVarietyID = value.TreeVarietyID
              ? value.TreeVarietyID
              : null;
            this.TreeVariety = value.VarietyName ? value.VarietyName : null;
            if (value.TreeVarietyID) this.GetByTreeVarietyID(value.TreeVarietyID);
            this.GetPictures(value);
            if (this.historyList) {
              this.showOverlay = false;
              // this.getTreeRegisters(
              //   this.filter,
              //   this.tree_type_id,
              //   this.province,
              //   this.district,
              //   this.subdistrict,
              //   this.user_level_id,
              //   this.phone_number,
              //   this.limit,
              //   this.itemsPerPage,
              //   false
              // );
            } else {
              if (value.Type == "Home") {
                if (this.TreeRegisterOld && this.TreeRegisterOld.Type === "Home") {
                  this.updateRegisterHomeStatus(
                    this.TreeRegister.ID,
                    this.TreeRegisterOld ? this.TreeRegisterOld.ID : "",
                    false
                  ); // อัปเดตเป็นอยู่ระหว่างดำเนินการ
                } else if (this.TreeRegisterOld && this.TreeRegisterOld.Type === "Public") {
                  this.updateRegisterPublicStatus(
                    this.TreeRegister.ID,
                    this.TreeRegisterOld ? this.TreeRegisterOld.ID : "",
                    false
                  ); // กลับไปเป็นรอดำเนินการ
                }
                this.updateRegisterHomeStatus(
                  value.ID,
                  "",
                  false
                ); // อัปเดตเป็นอยู่ระหว่างดำเนินการ


              } else {
                if (this.TreeRegisterOld && this.TreeRegisterOld.Type === "Home") {
                  this.updateRegisterHomeStatus(
                    this.TreeRegister.ID,
                    this.TreeRegisterOld ? this.TreeRegisterOld.ID : "",
                    false
                  ); // อัปเดตเป็นอยู่ระหว่างดำเนินการ
                } else if (this.TreeRegisterOld && this.TreeRegisterOld.Type === "Public") {
                  this.updateRegisterPublicStatus(
                    this.TreeRegister.ID,
                    this.TreeRegisterOld ? this.TreeRegisterOld.ID : "",
                    false
                  ); // กลับไปเป็นรอดำเนินการ
                }

                this.updateRegisterPublicStatus(
                  this.TreeRegister.ID,
                  "",
                  false
                );


              }
            }
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  indexActive: any;
  TreeRegister: any;
  TreeRegisterOld: any;
  TreeRegisterType: String;
  async SelectTreeRegister(value: any, index: any) {
    if (value) {
      this.TreeRegisterType = value.Type;
      if (this.TreeRegister) {
        this.TreeRegisterOld = this.TreeRegister;
        localStorage.setItem("type_tree_register", value.Type);
      } else {
        this.TreeRegisterOld = null;
      }
      this.TreeNameForNotification = value.Name;
      this.TreeRegisters[index].StatusName = this.historyList
        ? value.StatusName
        : "อยู่ระหว่างดำเนินการ";
      this.submittedNewTree = false;
      this.submittedVariety = false;
      this.submitted = false;
      this.showOverlay = true;
      this.TreeRegister = value;
      this.TreeRegister["Index"] = index;
      this.checkStatus(value, index);
    }
  }

  resetStatus() {
    this.httpClient
      .updateData(`/tree_register_public/reset/status`, {})
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.httpClient
              .updateData(`/tree_register_home/reset/status`, {})
              .pipe(first())
              .subscribe(
                (response: any) => {
                  if (response.status === true) {
                    this.getTreeRegisters(
                      this.filter,
                      this.tree_type_id,
                      this.province,
                      this.district,
                      this.subdistrict,
                      this.user_level_id,
                      this.phone_number,
                      0,
                      this.itemsPerPage,
                      true
                    );
                  } else {
                    this.alert.error(
                      "เกิดข้อผิดพลาด!",
                      this.error.error.message,
                      {
                        positionClass: "toast-bottom-left",
                      }
                    );
                  }
                },
                (error) => {
                  this.error = error;
                  if (this.error.error.message === "Invalid token") {
                    this.httpClient.logout();
                    location.href = "/login";
                  } else if (this.error.error.message === "Unauthorized") {
                    this.httpClient.logout();
                    location.href = "/login";
                  } else {
                    this.alert.error(
                      "เกิดข้อผิดพลาด!",
                      this.error.error.message,
                      {
                        positionClass: "toast-bottom-left",
                      }
                    );
                  }
                }
              );
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  updateRegisterHomeStatus(
    TreeHomeRegisterID: string,
    TreeHomeRegisterOldID: string,
    setStatus: boolean
  ) {
    this.httpClient
      .updateData(`/tree_register_home/update-register-status`, {
        TreeHomeRegisterID: TreeHomeRegisterID,
        TreeHomeRegisterOldID: TreeHomeRegisterOldID,
      })
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            if (setStatus) {
              localStorage.removeItem("last_tree_register");
              localStorage.removeItem("type_tree_register");
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.itemsPerPage,
                true
              );
            } else {
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.TreeRegisters.length,
                true
              );
            }
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", response.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  updateRegisterPublicStatus(
    TreePublicRegisterID: string,
    TreePublicRegisterOldID: string,
    setStatus: boolean
  ) {
    this.httpClient
      .updateData(`/tree_register_public/update-register-status`, {
        TreePublicRegisterID: TreePublicRegisterID,
        TreePublicRegisterOldID: TreePublicRegisterOldID,
      })
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            if (setStatus) {
              localStorage.removeItem("last_tree_register");
              localStorage.removeItem("type_tree_register");
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.itemsPerPage,
                true
              );
            } else {
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.TreeRegisters.length,
                true
              );
            }
          } else {
            this.getTreeRegisters(
              this.filter,
              this.tree_type_id,
              this.province,
              this.district,
              this.subdistrict,
              this.user_level_id,
              this.phone_number,
              0,
              this.TreeRegisters.length,
              true
            );
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  ChooseNewTree = false;
  onChooseTree(TreeId: any) {
    if (TreeId) {
      this.TreeID = TreeId;
      const Tree = this.Trees.find((Tree) => Tree.ID === Number(TreeId));
      this.TreeExamineForm.patchValue({
        TreeName: Tree.Name,
        Name: Tree.Name,
        TreeID: TreeId,
      });
      this.ChooseNewTree = true;
      this.getSearchVarieties("");
    }
  }

  onChooseTreeVariety(VarietyId: any) {
    if (VarietyId) {
      this.TreeVarietyID = VarietyId;
      const Variety = this.Varieties.find(
        (Variety: any) => Variety.ID === Number(VarietyId)
      );
      this.TreeExamineForm.patchValue({
        VarietyName: Variety.Name,
        VarietyID: Variety.ID,
        VarietyEngName: Variety.EngName,
        VarietyScientificName: Variety.ScientificName,
        VarietyFamilyNameID: Variety.TreeFamilyNameID
          ? Variety.TreeFamilyNameID
          : "",
        VarietyDescription: Variety.Description,
      });
    }
  }

  TreeID: any;
  submittedNewTree = false;
  AddNewTree() {
    this.submittedNewTree = true;
    if (
      this.TreeExamineForm.value.TreeTypeID == "" ||
      this.TreeExamineForm.value.TreeName == ""
    ) {
      return;
    }
    const NewTree = {
      Name: this.TreeExamineForm.value.TreeName,
      TreeTypeID: this.TreeExamineForm.value.TreeTypeID
        ? this.TreeExamineForm.value.TreeTypeID
        : null,
      Description: this.TreeExamineForm.value.Description
        ? this.TreeExamineForm.value.Description
        : null,
      EngName: this.TreeExamineForm.value.EngName
        ? this.TreeExamineForm.value.EngName
        : null,
      ScientificName: this.TreeExamineForm.value.ScientificName
        ? this.TreeExamineForm.value.ScientificName
        : null,
      TreeFamilyNameID: this.TreeExamineForm.value.TreeFamilyNameID
        ? this.TreeExamineForm.value.TreeFamilyNameID
        : null,
    };

    this.httpClient
      .createData(`/trees`, NewTree)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.submittedNewTree = false;
            this.TreeID = response.data.TreeID; // เดี๋ยว API จะส่ง TreeID กลับมาหลังจาก บันทึก
            this.GetByTreeID(this.TreeID);
            $("#modal-create-tree").modal("hide");
            this.alert.success("แจ้งเตือน!", "เพิ่มต้นไม้เข้าฐานข้อมูลสำเร็จ", {
              positionClass: "toast-bottom-left",
            });
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", response.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  TreeVarietyID: any;
  AddNewVariety() {
    this.submittedVariety = true;
    if (
      this.TreeExamineForm.value.VarietyName == "-" ||
      this.TreeExamineForm.value.VarietyName == ""
    ) {
      return;
    }
    const NewVariety = {
      Name: this.TreeExamineForm.value.VarietyName.Name
        ? this.TreeExamineForm.value.VarietyName.Name
        : this.TreeExamineForm.value.VarietyName,
      TreeID: this.TreeExamineForm.value.TreeID,
      EngName: this.TreeExamineForm.value.VarietyEngName,
      ScientificName: this.TreeExamineForm.value.VarietyScientificName,
      TreeFamilyNameID: this.TreeExamineForm.value.VarietyFamilyNameID
        ? this.TreeExamineForm.value.VarietyFamilyNameID
        : null,
      Description: this.TreeExamineForm.value.VarietyDescription,
    };
    this.httpClient
      .createData(`/tree_varieties`, NewVariety)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.TreeVarietyID = response.data.TreeVarietyID; // เดี๋ยว API จะส่ง TreeVarietyID กลับมาหลังจาก บันทึก
            this.submittedVariety = false;
            this.getSearchVarieties("");
            if (this.TreeVarietyID) this.GetByTreeVarietyID(this.TreeVarietyID);
            $("#modal-create-variety").modal("hide");
            this.alert.success(
              "แจ้งเตือน!",
              "เพิ่มสายพันธุ์เข้าฐานข้อมูลสำเร็จ",
              {
                positionClass: "toast-bottom-left",
              }
            );
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", response.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  Decline() {
    this.loading = true;
    this.indexActive = undefined;
    this.showOverlay = true;
    if (this.TreeRegister.Type == "Public") {
      const Update = {
        RegisterStatusID: "8da66b70-8348-11eb-8b4c-c197328307d1", //ปฏิเสธ
      };

      this.httpClient
        .updateData(`/tree_register_public/${this.TreeRegister.ID}`, Update)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status === true) {
              const index = this.TreeRegisters.indexOf(this.TreeRegister);
              if (index > -1) {
                this.TreeRegisters.splice(index, 1);
                if (this.TotalItem > 0) {
                  this.TotalItem += -1;
                }
              }
              this.loading = false;
              this.submitted = false;
              this.submittedVariety = false;
              $("#modal-decline").modal("hide");
              this.filter = "";
              this.TreeRegister = null;
              this.loading = false;
              localStorage.removeItem("last_tree_register");
              localStorage.removeItem("type_tree_register");
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.itemsPerPage,
                true
              );
              this.alert.success("แจ้งเตือน!", "ปฏิเสธข้อมูลสำเร็จ", {
                positionClass: "toast-bottom-left",
              });
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    } else {
      const PayloadUpdate = {
        RegisterStatusID: "8da66b70-8348-11eb-8b4c-c197328307d1", //ปฏิเสธ
      };
      this.httpClient
        .updateData(
          `/tree_register_home/update/status/${this.TreeRegister.ID
          }?updated_by=${localStorage.getItem("UserID")}`,
          PayloadUpdate
        )
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.TreeRegister = null;
            localStorage.removeItem("last_tree_register");
            localStorage.removeItem("type_tree_register");
            if (response.status === true) {
              this.submitted = false;
              this.submittedVariety = false;
              this.indexActive = undefined;
              const index = this.TreeRegisters.indexOf(this.TreeRegister);
              if (index > -1) {
                this.TreeRegisters.splice(index, 1);
                if (this.TotalItem > 0) {
                  this.TotalItem += -1;
                }
              }
              this.loading = false;
              this.filter = "";
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.itemsPerPage,
                true
              );
              $("#modal-decline").modal("hide");
              this.alert.success("แจ้งเตือน!", "บันทึกข้อมูลสำเร็จ", {
                positionClass: "toast-bottom-left",
              });
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    }
  }

  PreAccept() {
    this.TreeExamineForm.value.TreeName = this.TreeExamineForm.value.TreeName
      .Name
      ? this.TreeExamineForm.value.TreeName.Name
      : this.TreeExamineForm.value.TreeName;
    // if (this.TreeExamineForm.value.TreeName != this.TreeExamineForm.value.Name) {
    //   this.TreeID = null
    //   this.TreeVarietyID = null
    //   this.TreeVariety = null
    //   this.TreeExamineForm.patchValue({
    //     TreeID: null,
    //     TreeTypeID: '',
    //     TreeName: '',
    //     Name: '',
    //     Description: '',
    //     EngName: '',
    //     ScientificName: '',
    //     TreeFamilyName: '',
    //     TreeFamilyNameID: '',
    //     VarietyID: '',
    //     VarietyName: '',
    //     VarietyEngName: '',
    //     VarietyScientificName: '',
    //     VarietyFamilyName: '',
    //     VarietyFamilyNameID: '',
    //     VarietyDescription: '',
    //   });
    // }
    this.submitted = true;
    this.submittedVariety = false;
    if (!this.TreeExamineForm.value.TreeTypeID) {
      this.alert.warning("ข้อมูลผิดพลาด", "ยังไม่มีประเภทต้นไม้", {
        positionClass: "toast-top-right",
      });
      return;
    }
    if (this.TreeExamineForm.invalid) {
      return;
    }

    $("#modal-accept").modal("show");
  }

  Accept() {
    this.submitted = true;
    if (this.TreeExamineForm.invalid) {
      return;
    }
    this.loading = true;
    if (this.TreeRegister.Type == "Public") {
      const PayloadUpdate = {
        TreeID: this.TreeID,
        Name: this.TreeExamineForm.value.TreeName,
        Variety: this.TreeVarietyID ? this.TreeVariety : null,
        TreeVarietyID: this.TreeVarietyID ? this.TreeVarietyID : null,
        RegisterStatusID: "6f24a670-801a-11eb-8444-96c2ce618bb6", // อนุมัติ
      };
      this.showOverlay = true;
      this.httpClient
        .updateData(
          `/tree_register_public/${this.TreeRegister.ID}`,
          PayloadUpdate
        )
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.loading = false;
            localStorage.removeItem("last_tree_register");
            localStorage.removeItem("type_tree_register");
            if (response.status === true) {
              this.indexActive = undefined;
              this.RegisterToTreePublic(this.TreeRegister);
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    } else {
      const PayloadUpdate = {
        TreeID: Number(this.TreeID),
        Name: this.TreeExamineForm.value.TreeName,
        Variety: this.TreeVarietyID ? this.TreeVariety : null,
        TreeVarietyID: this.TreeVarietyID ? this.TreeVarietyID : null,
        RegisterStatusID: "6f24a670-801a-11eb-8444-96c2ce618bb6", // อนุมัติ
      };
      this.showOverlay = true;
      this.httpClient
        .updateData(
          `/tree_register_home/update/status/${this.TreeRegister.ID
          }?updated_by=${localStorage.getItem("UserID")}`,
          PayloadUpdate
        )
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.TreeRegister = null;
            localStorage.removeItem("last_tree_register");
            localStorage.removeItem("type_tree_register");
            if (response.status === true) {
              this.submitted = false;
              this.submittedVariety = false;
              this.indexActive = undefined;
              const index = this.TreeRegisters.indexOf(this.TreeRegister);
              if (index > -1) {
                this.TreeRegisters.splice(index, 1);
                if (this.TotalItem > 0) {
                  this.TotalItem += -1;
                }
              }
              this.loading = false;
              this.filter = "";
              this.getTreeRegisters(
                this.filter,
                this.tree_type_id,
                this.province,
                this.district,
                this.subdistrict,
                this.user_level_id,
                this.phone_number,
                0,
                this.itemsPerPage,
                true
              );
              $("#modal-accept").modal("hide");
              this.alert.success("แจ้งเตือน!", "บันทึกข้อมูลสำเร็จ", {
                positionClass: "toast-bottom-left",
              });
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    }
  }

  RegisterToTreePublic(TreeRegister) {
    this.httpClient
      .getData(`/tree_register_public/${TreeRegister.ID}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status == true) {
            let tree_register_public = response.data;
            const TreePublic = {
              AgencyID: tree_register_public.AgencyID
                ? tree_register_public.AgencyID
                : null,
              PlaceID: tree_register_public.PlaceID
                ? tree_register_public.PlaceID
                : null,
              TreeID: this.TreeID,
              TreeVarietyID: this.TreeVarietyID,
              Circumference: tree_register_public.Circumference
                ? tree_register_public.Circumference
                : null,
              HeightToEye: tree_register_public.HeightToEye
                ? tree_register_public.HeightToEye
                : null,
              Distance: tree_register_public.Distance
                ? tree_register_public.Distance
                : null,
              Angle: tree_register_public.Angle
                ? tree_register_public.Angle
                : null,
              Tan: tree_register_public.Tan ? tree_register_public.Tan : null,
              Height: tree_register_public.Height
                ? tree_register_public.Height
                : null,
              SpreadingBranches: tree_register_public.SpreadingBranches
                ? tree_register_public.SpreadingBranches
                : null,
              Note: tree_register_public.Note
                ? tree_register_public.Note
                : null,
              Latitude: tree_register_public.Latitude
                ? tree_register_public.Latitude
                : null,
              Longitude: tree_register_public.Longitude
                ? tree_register_public.Longitude
                : null,
            };
            this.httpClient
              .createData(
                `/tree_public?created_by=${TreeRegister.CreatedBy}&tree_register_id=${TreeRegister.ID}`,
                TreePublic
              )
              .pipe(first())
              .subscribe(
                (response: any) => {
                  if (response.status === true) {
                    this.submitted = false;
                    this.submittedVariety = false;
                    const index = this.TreeRegisters.indexOf(this.TreeRegister);
                    if (index > -1) {
                      this.TreeRegisters.splice(index, 1);
                      if (this.TotalItem > 0) {
                        this.TotalItem += -1;
                      }
                    }
                    this.filter = "";
                    this.TreeRegister = null;
                    this.loading = false;
                    this.getTreeRegisters(
                      this.filter,
                      this.tree_type_id,
                      this.province,
                      this.district,
                      this.subdistrict,
                      this.user_level_id,
                      this.phone_number,
                      0,
                      this.itemsPerPage,
                      true
                    );
                    $("#modal-accept").modal("hide");
                    this.alert.success("แจ้งเตือน!", "บันทึกข้อมูลสำเร็จ", {
                      positionClass: "toast-bottom-left",
                    });
                  } else {
                    this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                      positionClass: "toast-bottom-left",
                    });
                  }
                },
                (error) => {
                  this.error = error;
                  if (this.error.error.message === "Invalid token") {
                    this.httpClient.logout();
                    location.href = "/login";
                  } else if (this.error.error.message === "Unauthorized") {
                    this.httpClient.logout();
                    location.href = "/login";
                  } else {
                    this.alert.error(
                      "เกิดข้อผิดพลาด!",
                      this.error.error.message,
                      {
                        positionClass: "toast-bottom-left",
                      }
                    );
                  }
                }
              );
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  GetPictures(value) {
    this.Pictures = [];
    if (value.Type == "Public") {
      this.httpClient
        .getData(`/tree_public_picture/tree_register_pulic/${value.ID}`)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status === true) {
              let pictures = response.data ? response.data : [];
              if (value.Picture) {
                pictures.push({ Picture: value.Picture, OldPicture: true });
              }
              for (let i = 0; i < pictures.length; i++) {
                const album = {
                  src: pictures[i].OldPicture
                    ? `${this.url}/tree-public/${pictures[i].Picture}`
                    : `${this.url}/tree-public-picture/${pictures[i].Picture}`,
                  picture: pictures[i].Picture,
                  old_picture: pictures[i].Picture,
                  caption: `${i + 1}/${pictures.length}`,
                  OldPicture: pictures[i].OldPicture
                    ? pictures[i].OldPicture
                    : false,
                };
                this.Pictures.push(album);
              }
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    } else {
      this.httpClient
        .getData(`/tree_register_home/${value.ID}`)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status === true) {
              if (value.Picture) {
                this.Pictures = response.data
                  ? [
                    {
                      src: `${this.url}/tree-home/${value.Picture}`,
                      picture: value.Picture,
                      old_picture: value.Picture,
                      caption: `1`,
                      OldPicture: true,
                    },
                  ]
                  : [];
              }
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    }
  }

  modalCanEditFund = true;
  onScrollToEnd() {
    this.itemsPerSelect = this.itemsPerSelect + this.limit;
    this.getTrees(this.filter);
  }

  onScrollTree({ end }) {
    if (this.modalCanEditFund || this.config.totalItems <= this.Trees.length) {
      return;
    }
    if (end + this.itemsPerSelect >= this.Trees.length) {
      this.getTrees(this.filter);
    }
  }

  SelectTrees(value: any) {
    this.TreeExamineForm.patchValue({
      TreeID: value,
    });
  }

  keyword = "Name";
  selectEvent(item: any) {
    if (item) {
      this.TreeID = item.ID;
      this.TreeExamineForm.patchValue({
        TreeTypeID: item.TreeTypeID ? item.TreeTypeID : "",
        TreeName: item.Name,
        Name: item.Name,
        TreeID: item.ID,
        Description: item.Description,
        EngName: item.EngName,
        ScientificName: item.ScientificName,
        TreeFamilyName: item.TreeFamilyName ? item.TreeFamilyName : "",
        TreeFamilyNameID: item.TreeFamilyNameID ? item.TreeFamilyNameID : "",
      });
      this.ChooseNewTree = true;
      this.getSearchVarieties("");
    }
    // do something with selected item
  }

  onClearTree() {
    this.submitted = false;
    this.submittedNewTree = false;
    this.TreeID = "";
    this.TreeVarietyID = "";
    this.TreeExamineForm.patchValue({
      TreeTypeID: "",
      TreeName: "",
      Name: "",
      TreeID: "",
      Description: "",
      EngName: "",
      ScientificName: "",
      TreeFamilyName: "",
      TreeFamilyNameID: "",
      VarietyID: "",
      VarietyName: "",
      VarietyEngName: "",
      VarietyScientificName: "",
      VarietyFamilyName: "",
      VarietyFamilyNameID: "",
      VarietyDescription: "",
    });
  }

  onClearTreeFamilyName() {
    this.TreeExamineForm.patchValue({
      TreeFamilyNameID: "",
    });
  }

  onClearVariety() {
    this.TreeVarietyID = "";
    this.TreeVariety = "";
    this.submittedVariety = false;
    this.TreeExamineForm.patchValue({
      VarietyName: "",
      VarietyID: "",
      VarietyEngName: "",
      VarietyScientificName: "",
      VarietyFamilyName: "",
      VarietyFamilyNameID: "",
      VarietyDescription: "",
    });
  }

  onClearVarietyFamilyName() {
    this.TreeExamineForm.patchValue({
      VarietyFamilyNameID: "",
    });
  }

  TreeVariety: any;
  selectVariety(item: any) {
    if (item) {
      this.TreeVarietyID = item.ID;
      this.TreeVariety = item.Name;
      this.TreeExamineForm.patchValue({
        VarietyName: item.Name,
        VarietyID: item.ID,
        VarietyEngName: item.EngName,
        VarietyScientificName: item.ScientificName,
        VarietyFamilyName: item.TreeFamilyName ? item.TreeFamilyName : "",
        VarietyFamilyNameID: item.TreeFamilyNameID ? item.TreeFamilyNameID : "",
        VarietyDescription: item.Description,
      });
      this.ChooseNewTree = true;
      this.getSearchVarieties(item.Name);
    }
    // do something with selected item
  }

  selectTreeFamilyName(item: any) {
    if (item) {
      this.TreeExamineForm.patchValue({
        TreeFamilyNameID: item.ID,
      });
    }
  }

  selectVarietyFamilyName(item: any) {
    if (item) {
      this.TreeExamineForm.patchValue({
        VarietyFamilyNameID: item.ID,
      });
    }
  }

  TreeType: any;
  getTreeType() {
    this.httpClient
      .getData("/tree_types?data_type_id=1")
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.TreeType = response.data.data;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  UserLevels = [];
  getUserLevels() {
    this.httpClient
      .getData(`/user_levels`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.UserLevels = response.data ? response.data.data : [];
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  onChangeSearch(item: string) {
    if (item) {
      this.getTrees(item);
    }
  }

  onChangeVarietySearch(item: string) {
    if (item) {
      this.getSearchVarieties(item);
    }
  }

  onFocused(e) {
    if (e) {
      this.getTrees(e);
    }
  }

  onVarietyFocused(e) {
    if (e) {
      this.getSearchVarieties(e);
      // this.getTrees(item, this.limit, this.itemsPerSelect,true)
    }
  }

  Provinces: any;
  getProvinces() {
    this.httpClient
      .getData("/provinces")
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.Provinces = response.data;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  onChooseProvince(ProvinceID) {
    this.province = ProvinceID;
    this.district = "";
    this.subdistrict = "";
    this.getDistricts(ProvinceID);
    this.filterSearch();
  }

  Districts: any;
  getDistricts(ProvinceID) {
    this.httpClient
      .getData(`/districrs/${ProvinceID}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.Districts = response.data;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  onChooseDistrict(DistrictID) {
    this.district = DistrictID;
    this.subdistrict = "";
    this.getSubDistricts(DistrictID);
    this.filterSearch();
  }

  Subdistricts: any;
  getSubDistricts(DistrictID) {
    this.httpClient
      .getData(`/subdistricts/${DistrictID}`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.Subdistricts = response.data;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  onChooseSubDistrict(SubDistrictID) {
    this.subdistrict = SubDistrictID;
    this.filterSearch();
  }
}
