import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppURL } from './app.url';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './components/_helpers/auth.guard';
import { LogoutGuard } from './components/_helpers/logout.guard';


const routes: Routes = [
  { path: "", redirectTo: AppURL.Login, pathMatch: "full" },
  { path: AppURL.Login, component: LoginComponent, canActivate: [LogoutGuard] },
  { path: AppURL.Admin, loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
