import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpClientService {
  public token: string;
  serveURL = environment.apiUrl;
  isLogin = null;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private name = new BehaviorSubject<string>(null);
  readonly name$ = this.name.asObservable();

  private avatar = new BehaviorSubject<string>(null);
  readonly avatar$ = this.avatar.asObservable();

  private treePartSubject = new BehaviorSubject<any[]>([]);
  public treePart$ = this.treePartSubject.asObservable();

  RoleID = localStorage.getItem("RoleID");

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    const dataUser = JSON.parse(localStorage.getItem("currentUser"));
    this.isLogin = localStorage.getItem("isLogin");
    if (dataUser && dataUser.token) {
      this.token =  dataUser.token.access_token;
    }
  }

  nameLocal = new BehaviorSubject(this.currentName);
  avatarLocal = new BehaviorSubject(this.currentAvatar);
  logoLocal = new BehaviorSubject(this.currentLogo);
  examinePlace = new BehaviorSubject(this.currentExaminePlace);
  examineTree = new BehaviorSubject(this.currentExamineTree);
  examineDot = new BehaviorSubject(this.currentExamineDot);
  examineTreeProblem = new BehaviorSubject(this.currentExamineTreeProblem);
  examineTreeProject = new BehaviorSubject(this.currentExamineTreeProject);
  examineAssessmentTree = new BehaviorSubject(this.currentAssessmentTree);

  set currentName(value) {
    this.nameLocal.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("fullname", value);
  }

  set currentAvatar(value) {
    this.avatarLocal.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("Avatar", value);
  }

  set currentLogo(value) {
    this.logoLocal.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("Logo", value);
  }

  set currentExaminePlace(value) {
    this.examinePlace.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("examinePlace", value);
  }

  set currentExamineTree(value) {
    this.examineTree.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("examineTree", value);
  }

  set currentExamineDot(value: any) {
    this.examineDot.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("examineDot", value);
  }

  set currentExamineTreeProblem(value) {
    this.examineTreeProblem.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("examineTreeProblem", value);
  }

  set currentAssessmentTree(value) {
    this.examineAssessmentTree.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("assessmentTree", value);
  }

  set currentExamineTreeProject(value) {
    this.examineTreeProject.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem("examineTreeProject", value);
  }
  

  get currentName() {
    return localStorage.getItem("fullname");
  }

  get currentAvatar() {
    return localStorage.getItem("Avatar");
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get isLoginValue(): any {
    return this.isLogin;
  }

  updateTreePart(treePartData: any[]) {
    this.treePartSubject.next(treePartData);
  }

  displayUsername(name: string) {
    this.name.next(name);
  }

  displayAvatar(avatar: string) {
    this.avatar.next(avatar);
  }

  getData(path) {
    return this.httpClient.get(this.serveURL + path, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  createData(path, data) {
    return this.httpClient.post(this.serveURL + path, data, {
      headers: {
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  updateData(path, data) {
    return this.httpClient.put(this.serveURL + path, data, {
      headers: {
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }
  updatePatchData(path, data) {
    return this.httpClient.patch(this.serveURL + path, data, {
      headers: {
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  removeData(path) {
    return this.httpClient.delete(this.serveURL + path, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  // Province , Districts, SubDistricts
  getProvinces() {
    return this.httpClient.get(`${this.serveURL}/provinces`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  getDistricts(ProvinceID: string) {
    return this.httpClient.get(`${this.serveURL}/districts/${ProvinceID}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  getSubDistricts(DistrictID: string) {
    return this.httpClient.get(`${this.serveURL}/subdistricts/${DistrictID}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        Permission: this.RoleID,
      },
    });
  }

  login(PhoneNumber: string, Password: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/admin/signin`, {
        PhoneNumber: PhoneNumber,
        Password: Password,
      })
      .pipe(
        map((response: any) => {
          if (response.status === true) {
            let fullname = response.data.Name + " " + response.data.Surname;
            localStorage.setItem("currentUser", JSON.stringify(response));
            localStorage.setItem("isLogin", "1");
            localStorage.setItem("name", response.data.Name);
            localStorage.setItem("surname", response.data.Surname);
            localStorage.setItem(
              "fullname",
              response.data.Name + " " + response.data.Surname
            );
            localStorage.setItem("UserID", response.data.ID);
            localStorage.setItem("RoleID", response.data.RoleID);
            localStorage.setItem("RoleName", response.data.RoleName);
            localStorage.setItem("Avatar", response.data.Picture);
            this.currentName = fullname;
            this.currentUserSubject.next(response);
            return response;
          } else {
            return response;
          }
        })
      );
  }

  logout() {
    this.currentName = "";
    localStorage.removeItem("currentUser");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("name");
    localStorage.removeItem("surname");
    localStorage.removeItem("fullname");
    localStorage.removeItem("UserID");
    localStorage.removeItem("RoleID");
    localStorage.removeItem("RoleName");
    localStorage.removeItem("Avatar");
    this.currentUserSubject.next(null);
  }
}
