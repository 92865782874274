export const AdminURL = {
  Index: "index",
  Setting: "setting",
  Dashboard: "dashboard",
  Profile: "profile",
  Examine: "examine",
  Users: "users",
  AnimalData: "animals-data",
  TreeHome: "tree-home",
  TreePublic: "tree-public",
  TreeData: "trees-data",

  Animals: "animals",

  Databases: "databases",

  WebsiteSetting: "website-setting",
  WebPresses: "web-presses",
  WebPrizes: "web-prizes",

  //Feedback
  Feedback: "feedback",

  Families: "families",

  // Dot
  Dot: "dot",
  DotType: "dot-type",

  //Partners
  Partners: "partners",
  Manage: "manage",

  // Fund
  Funds: "funds",

  //DotPublic
  DotPublic: "dot-public",

  //Examine
  TreeExamine: "tree-examine",
  TreeUpdateExamine: "tree-update-examine",
  PlaceExamine: "place-examine",
  TreeProblemExamine: "tree-problem-examine",
  TreeProject: "tree-project",
  DotExamine: "dot-examine",
  //Assessment
  TreeAssessment: "tree-assessment",

  //Tree
  TreeRegisterHome: "tree-register-home",
  TreeRegisterPublic: "tree-register-public",

  //Setting
  TreeFamilyName: "tree-family-name",
  Admin: "administrators",
  Materials: "materails",
  Soils: "soils",
  LightTime: "light-time",
  LightIntensities: "light-intensities",
  WaterContents: "water-contents",
  WaterTime: "water-time",
  TreeType: "tree-type",
  TreeRegisterHomeType: "tree-register-home-type",
  TreeRegisterPublicType: "tree-register-public-type",
  Trees: "trees",
  Variety: "variety",
  Agencies: "agencies",
  // Partners: "partners",
  AppGreeting: "app-greeting",
  CompanyProfile: "company-profile",
  WebAppContents: "web-page-contents",
  TreeCarbon: "tree-carbon",
  PopulationCarbon: "population-carbon",
  PlacePopulation: "place-population",
  PointGetting: "point-getting",
  Places: "places",
  PlaceTypes: "place-type",
  PlaceRegistration: "place_registration",
  Roles: "roles",
  Colors: "colors",
  Parts: "parts",
  TreePart: "tree-part",
  TreeBenefit: "tree-benefit",
  TreeSupport: "tree-support",
  GeneralSetting: "general-setting",
  AnimalSupport: "animal-support",
  ///Web Page Group

  WebPageGroup: "web-page-group",

  // Sub Router
  List: "list",
  Create: "create",
  Edit: "edit",
  View: "view",
  Detail: "detail",
};
