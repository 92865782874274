import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminURL } from 'src/app/admin/admin.url';
import { AppURL } from 'src/app/app.url';
import { HttpClientService } from '../../http-client/http-client.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css']
})
export class AdminNavbarComponent implements OnInit {

  AppURL = AppURL;
  AdminURL = AdminURL;
  
  constructor(
    private toastr: ToastrService,
    private httpClient: HttpClientService
  ) { }

  ngOnInit() {
  }

  logout() {
    this.toastr.success('Signout successful', 'Notification!');
    this.httpClient.logout();
    location.href = '/login';
  }

}
