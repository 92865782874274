import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminURL } from "src/app/admin/admin.url";
import { AppURL } from "src/app/app.url";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClientService } from "../../http-client/http-client.service";
import { SocketioService } from "src/app/shareds/socket/socketio.service";
import { TreeExamineComponent } from "../../../admin/components/examine/tree-examine/tree-examine.component";

declare const App: any;

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.css"],
})
export class AdminSidebarComponent implements OnInit {
  @ViewChild(TreeExamineComponent, { static: false })
  tree_examine: TreeExamineComponent;

  AppURL = AppURL;
  AdminURL = AdminURL;
  UserID = localStorage.getItem("UserID");
  RoleID = Number(localStorage.getItem("RoleID"));
  error: any;
  profile: any;
  DisplayName: any;
  DisplayRole = localStorage.getItem("RoleName");

  DisplayAvatar: any;
  DisplayLogo: any;

  AvatarDefault = "assets/media/users/default.jpg";
  LogoDefault = "assets/media/icons/default-placeholder.png";
  url = environment.apiUrl;
  constructor(
    private alert: ToastrService,
    private httpClient: HttpClientService,
    private socketService: SocketioService
  ) {
    this.httpClient.nameLocal.subscribe((nextValue) => {
      this.DisplayName = nextValue; // this will happen on every change
    });
    this.httpClient.avatarLocal.subscribe((nextValue) => {
      if (nextValue != "null") {
        this.DisplayAvatar = `${this.url}/avatar/${nextValue}`; // this will happen on every change
      } else {
        this.DisplayAvatar = "assets/media/users/default.jpg"; // this will happen on every change
      }
    });
    this.httpClient.logoLocal.subscribe((nextValue) => {
      if (nextValue != "null" || nextValue) {
        this.DisplayLogo = nextValue
          ? `${this.url}/company-logo/${nextValue}`
          : "assets/media/icons/default-placeholder.png"; // this will happen on every change
      } else {
        this.DisplayLogo = "assets/media/icons/default-placeholder.png"; // this will happen on every change
      }
    });
    this.httpClient.examineTree.subscribe((nextValue) => {
      this.TreeCount = nextValue
    });
    this.httpClient.examineDot.subscribe((nextValue) => {
      this.DotCount = nextValue
    });
    this.httpClient.examinePlace.subscribe((nextValue) => {
      this.PlaceCount = nextValue
    });
    this.httpClient.examineTreeProblem.subscribe((nextValue) => {
      this.CountTreeProblem = nextValue
    });
    this.httpClient.examineAssessmentTree.subscribe((nextValue) => {
      this.CountAssessment = nextValue
    });
    this.httpClient.examineTreeProject.subscribe((nextValue) => {
      this.CountTreeProject = nextValue; // this will happen on every change
    })
  }

  ngOnInit() {
    App.initialLoadPage();
    this.getCompanyProfile();
    // this.getExaminePlace();
    // this.GetPlaceCountBySocket();
    // this.getExamineTree();
    // this.GetTreeCountBySocket();
    // this.getExamineTreeProblem();
    // this.GetTreeProblemCountBySocket();
    // this.getExamineTreeProject();
    // this.GetTreeProjectCountBySocket();
    // this.getTreeAssessment();
    // this.GetAssessmentCountBySocket();
    this.fetchNotification()
  }

  setStatus() {
    const ID = localStorage.getItem("last_tree_register");
    if (localStorage.getItem("type_tree_register") == "Home") {
      this.updateRegisterHomeStatus(
        ID,
        "6ae66080-801a-11eb-8d09-8755e97472d0",
        true
      ); // กลับไปเป็นรอดำเนินการ
    } else if (localStorage.getItem("type_tree_register") == "Public") {
      this.updateRegisterPublicStatus(
        ID,
        "6ae66080-801a-11eb-8d09-8755e97472d0",
        true
      ); // กลับไปเป็นรอดำเนินการ
    } else {
      this.fetchNotification();
    }

    // TREE PROJECT
    const TreeProjectID = localStorage.getItem("last_tree_project");
    if (TreeProjectID) {
      this.updateTreeProjectStatus(TreeProjectID, false, true); // กลับไปเป็นรอดำเนินการ
    } else {
      this.fetchNotification();
    }

    // PLACE
    const PlaceID = localStorage.getItem("last_place_register");
    if (PlaceID) {
      this.updateRegisterPublicStatus(
        PlaceID,
        "6ae66080-801a-11eb-8d09-8755e97472d0",
        true
      ); // กลับไปเป็นรอดำเนินการ
    } else {
      this.fetchNotification();
    }

    // Risk Assessment
    const RiskAssessmentID = localStorage.getItem("last_assessment");
    const RiskAssessmentStatus = localStorage.getItem("last_assessment_status");

    if (RiskAssessmentID && RiskAssessmentStatus !== "อนุมัติ") {
      this.updateRiskAssessmentStatus(
        RiskAssessmentID,
        "6ae66080-801a-11eb-8d09-8755e97472d0"
      ); // กลับไปเป็นรอดำเนินการ
    } else {
      this.fetchNotification();
    }
  }

  TreeCount: Number = 0
  PlaceCount: Number = 0
  DotCount: Number = 0
  CountTreeProblem: Number = 0;
  CountAssessment: Number = 0;
  CountTreeProject: Number = 0
  CountFeedback: Number = 0
  fetchNotification() {
    this.httpClient
      .getData(`/notification/countAll`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.TreeCount = response.data ? response.data.examine_tree : 0
          this.PlaceCount = response.data ? response.data.examine_place : 0
          this.DotCount = response.data ? response.data.examine_dot : 0
          this.CountTreeProblem = response.data ? response.data.examine_problem_tree : 0
          this.CountAssessment = response.data ? response.data.examine_assessment_tree : 0
          this.CountTreeProject = response.data ? response.data.examine_tree_project : 0
          this.CountFeedback = response.data ? response.data.examine_feedback : 0

          this.sendTreeCountBySocket(this.TreeCount);
          this.sendPlaceCountBySocket(this.PlaceCount);
          // Dot
          this.sendTreeProblemCountBySocket(this.CountTreeProblem);
          this.sendAssessmentCountBySocket(this.CountAssessment);
          this.sendTreeProjectCountBySocket(this.CountTreeProject);
          // Feedback

        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  getCompanyProfile() {
    this.httpClient
      .getData(`/company_profile`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.DisplayLogo = `${this.url}/company-logo/${response.data.LogoWhite}`;
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }


  GetAssessmentCountBySocket() {
    this.socketService
      .getCountAssessment()
      .subscribe((countAssessment: any) => {
        this.CountAssessment = countAssessment;
      });
  }

  sendAssessmentCountBySocket(countAssessment: any) {
    this.socketService.sendCountAssessment(countAssessment);
  }

 // TREE
  GetTreeCountBySocket() {
    this.socketService.getCountTree().subscribe((countTree: any) => {
      this.TreeCount = countTree;
    });
  }
  sendTreeCountBySocket(countTree: any) {
    this.socketService.sendCountTree(countTree);
  }


  // DOT
  GetDotCountBySocket() {
    this.socketService.getCountDot().subscribe((countDot: any) => {
      this.TreeCount = countDot;
    });
  }
  sendDotCountBySocket(countDot: any) {
    this.socketService.sendCountDot(countDot);
  }

  GetTreeProblemCountBySocket() {
    this.socketService
      .getCountTreeProblem()
      .subscribe((countTreeProblem: any) => {
        this.CountTreeProblem = countTreeProblem;
      });
  }

  sendTreeProblemCountBySocket(countTreeProblem: any) {
    this.socketService.sendCountTreeProblem(countTreeProblem);
  }


  GetTreeProjectCountBySocket() {
    this.socketService
      .getCountTreeProject()
      .subscribe((countTreeProject: any) => {
        this.CountTreeProject = countTreeProject;
      });
  }

  sendTreeProjectCountBySocket(countTreeProject: any) {
    this.socketService.sendCountTreeProject(countTreeProject);
  }


  GetPlaceCountBySocket() {
    this.socketService.getCountPlace().subscribe((placecount: any) => {
      this.PlaceCount = placecount;
    });
  }

  sendPlaceCountBySocket(place: any) {
    this.socketService.sendCountPlace(place);
  }

  // update status
  updateRegisterHomeStatus(ID: any, RegisterStatusID: string, setStatus: boolean) {
    this.httpClient
      .updateData(`/tree_register_home/update/status/${ID}`, {
        RegisterStatusID: RegisterStatusID,
      })
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            if (setStatus) {
              localStorage.removeItem("last_tree_register");
              localStorage.removeItem("type_tree_register");
              this.fetchNotification();
            } else {
              this.fetchNotification();
            }
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", response.message, {
              positionClass: "toast-bottom-left",
            });
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  updateRegisterPublicStatus(ID: any, RegisterStatusID: string, setStatus: boolean) {
    this.httpClient
      .updateData(`/tree_register_public/${ID}`, {
        RegisterStatusID: RegisterStatusID,
      })
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            if (setStatus) {
              localStorage.removeItem("last_tree_register");
              localStorage.removeItem("type_tree_register");
              this.fetchNotification();
            } else {
              this.fetchNotification();
            }
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  updateTreeProjectStatus(ID: any, Status: boolean, setStatus: boolean) {
    this.httpClient
      .updateData(`/tree_project/${ID}`, { ProcessActive: Status })
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            if (setStatus) {
              localStorage.removeItem("last_tree_project");
              this.fetchNotification();
            } else {
              this.fetchNotification();
            }
          }
        },
        (error) => {
          this.error = error;
          if (this.error.error.message === "Invalid token") {
            this.httpClient.logout();
            location.href = "/login";
          } else if (this.error.error.message === "Unauthorized") {
            this.httpClient.logout();
            location.href = "/login";
          } else {
            this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
              positionClass: "toast-bottom-left",
            });
          }
        }
      );
  }

  updateRiskAssessmentStatus(ID: any, RegisterStatusID: string) {
    if (ID) {
      this.httpClient
        .updateData(`/tree_public_risk_assessment/${ID}`, {
          RegisterStatusID: RegisterStatusID,
        })
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status === true) {
              localStorage.removeItem("last_assessment");
              localStorage.removeItem("last_assessment_status");
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", response.message, {
                positionClass: "toast-bottom-left",
              });
            }
          },
          (error) => {
            this.error = error;
            if (this.error.error.message === "Invalid token") {
              this.httpClient.logout();
              location.href = "/login";
            } else if (this.error.error.message === "Unauthorized") {
              this.httpClient.logout();
              location.href = "/login";
            } else {
              this.alert.error("เกิดข้อผิดพลาด!", this.error.error.message, {
                positionClass: "toast-bottom-left",
              });
            }
          }
        );
    } else {
      localStorage.removeItem("last_assessment");
      localStorage.removeItem("last_assessment_status");
    }
  }

  logout() {
    this.alert.success("แจ้งเตือน!", "Signout successful", {
      positionClass: "toast-bottom-left",
    });
    this.httpClient.logout();
    location.href = "/login";
  }

  PictureDefault = "assets/media/icons/default-placeholder.png";
  checkError = false;
  errorHandler(event: any) {
    this.checkError = true;
    event.target.src = this.PictureDefault;
  }
}
