import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClientService } from 'src/app/shareds/http-client/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpClient: HttpClientService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isLogin = this.httpClient.isLoginValue;
    if (isLogin === '1') {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/admin'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    // logged in so return true
    return true;
  }
  
}
