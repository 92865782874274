import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminURL } from 'src/app/admin/admin.url';
import { AppURL } from 'src/app/app.url';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../http-client/http-client.service';
import { SocketioService } from 'src/app/shareds/socket/socketio.service';
import { TreeExamineComponent } from '../../../admin/components/examine/tree-examine/tree-examine.component';

declare const App: any;

@Component({
  selector: 'app-admin-content',
  templateUrl: './admin-content.component.html',
  styleUrls: ['./admin-content.component.css']
})
export class AdminContentComponent implements OnInit {

  error: any;
  DisplayLogo: string;
  url = environment.apiUrl

  LogoDefault = "assets/media/icons/default-placeholder.png"

  constructor(
    private alert: ToastrService,
    private httpClient: HttpClientService,
  ) {
    this.httpClient.logoLocal.subscribe((nextValue: any) => {
      if (nextValue != 'null' || nextValue) {
        this.DisplayLogo = nextValue ? `${this.url}/company-logo/${nextValue}` : "assets/media/icons/default-placeholder.png"; // this will happen on every change
      } else {
        this.DisplayLogo = "assets/media/icons/default-placeholder.png" // this will happen on every change
      }
    })
   }

  ngOnInit(){
    // App.initialLoadPage();
    this.getCompanyProfile()
  }

  getCompanyProfile() {
    this.httpClient.getData(`/company_profile`).pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.DisplayLogo = `${this.url}/company-logo/${response.data.LogoNormal}`
          }
        },
        error => {
          this.error = error;
          if (this.error.error.message === 'Invalid token') {
            this.httpClient.logout();
            location.href = '/login';
          } else if (this.error.error.message === 'Unauthorized') {
            this.httpClient.logout();
            location.href = '/login';
          } else {
            this.alert.error('เกิดข้อผิดพลาด!', this.error.error.message, {
              positionClass: 'toast-bottom-left',
            });
          }
        });
  }

}
