import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminContentComponent } from "./components/admin-content/admin-content.component";
import { AdminNavbarComponent } from "./components/admin-navbar/admin-navbar.component";
import { AdminSidebarComponent } from "./components/admin-sidebar/admin-sidebar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DataTablesModule } from "angular-datatables";

import { NgxImageCompressService } from "ngx-image-compress";

@NgModule({
  declarations: [
    AdminContentComponent,
    AdminNavbarComponent,
    AdminSidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
  ],
  exports: [
    AdminSidebarComponent,
    AdminNavbarComponent,
    AdminContentComponent,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
  ],
  providers: [NgxImageCompressService],
})
export class SharedsModule {}
