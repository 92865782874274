import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  public countplace$: BehaviorSubject<string> = new BehaviorSubject('');
  public countTree$: BehaviorSubject<string> = new BehaviorSubject('');
  public countDot$: BehaviorSubject<string> = new BehaviorSubject('');
  public countTreeProblem$: BehaviorSubject<string> = new BehaviorSubject('');
  public TreeProblemData$: BehaviorSubject<string> = new BehaviorSubject('');
  public countTreeProject$: BehaviorSubject<string> = new BehaviorSubject('');
  public TreeProjectData$: BehaviorSubject<string> = new BehaviorSubject('');
  public countAssessment$: BehaviorSubject<string> = new BehaviorSubject('');
  public assessmentData$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }
  socket = io(environment.apiUrl);

  // Place
  public sendCountPlace(place: any) {
    this.socket.emit('countplace', place);
  }

  public getCountPlace = () => {
    this.socket.on('countplace', (place) => {
      this.countplace$.next(place)
    })
    return this.countplace$.asObservable();
  }


  // Tree
  public sendCountTree(countTree: any) {
    this.socket.emit('countTree', countTree);
  }

  public getCountTree = () => {
    this.socket.on('countTree', (countTree) => {
      this.countTree$.next(countTree)
    })
    return this.countTree$.asObservable();
  }

  // Dot
  public sendCountDot(countDot: any) {
    this.socket.emit('countDot', countDot);
  }

  public getCountDot = () => {
    this.socket.on('countDot', (countDot) => {
      this.countDot$.next(countDot)
    })
    return this.countDot$.asObservable();
  }


  //Tree Update Socket
  public sendCountTreeProblem(countTreeProblem: any) {
    this.socket.emit('countTreeProblem', countTreeProblem);
  }

  public getCountTreeProblem = () => {
    this.socket.on('countTreeProblem', (countTreeProblem) => {
      this.countTreeProblem$.next(countTreeProblem)
    })
    return this.countTreeProblem$.asObservable();
  }

  public sendTreeProblemData(TreeProblemData: any) {
    this.socket.emit('TreeProblemData', TreeProblemData);
  }

  public getTreeProblemData = () => {
    this.socket.on('TreeProblemData', (TreeProblemData) => {
      this.TreeProblemData$.next(TreeProblemData)
    })
    return this.TreeProblemData$.asObservable();
  }

// Tree Project
public getCountTreeProject = () => {
  this.socket.on('countTreeProject', (countTreeProject) => {
    this.countTreeProject$.next(countTreeProject)
  })
  return this.countTreeProject$.asObservable();
}

public sendCountTreeProject(countTreeProject: any) {
  this.socket.emit('countTreeProject', countTreeProject);
}

//Assessment
  public sendCountAssessment(countAssessment: any) {
    this.socket.emit('countAssessment', countAssessment);
  }

  public getCountAssessment = () => {
    this.socket.on('countAssessment', (countAssessment) => {
      this.countAssessment$.next(countAssessment)
    })
    return this.countAssessment$.asObservable();
  }

}
